import request from 'graphql-request';

import { GRAPHQL_API_URI } from '../../../../../config';

import {
  FetchCategoriesFilters,
  FetchCategoriesSort,
  FetchCategoriesPage,
  FetchCategoriesLimit
} from '../../../categoriesTypes';

import { getQueryName } from '../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../utils/productionConsole';
import { generateUuid } from '../../../../../utils/generateUuid';

interface FetchCategoriesProps {
  query: string;
  filters: FetchCategoriesFilters;
  sort: FetchCategoriesSort;
  page: FetchCategoriesPage;
  limit: FetchCategoriesLimit;
}

export function fetchCategories({
  query,
  filters,
  sort,
  page,
  limit
}: FetchCategoriesProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchCategories',
    { filters, sort, page, limit },
    rawName,
    JSON.stringify(filters)
  );

  const requestId = generateUuid();

  return request(
    `${GRAPHQL_API_URI}?queryName=${name}&requestId=${requestId}`,
    query,
    {
      filters,
      sort,
      limit,
      offset: (page - 1) * limit
    },
    { requestId }
  );
}
